const MODALS = {
  ACCEPT_TECHNICAL_PRICE: "AcceptTechnicalPriceModal",
  ATTACHMENTS: "AttachmentsModal",
  BESPOKE_EDIT: "EditBespokeModal",
  BIND_ENDORSEMENT_QUOTE: "BindEndoQuoteModal",
  BIND_QUOTE_SUCCESS: "BindQuoteSuccessModal",
  BIND_QUOTE: "BindQuoteModal",
  CANCEL_ENDORSEMENT: "CancelEndorsementModal",
  CHANGE_COMMISSION: "ChangeCommissionModal",
  CLAUSE_EDIT: "EditClauseModal",
  CREATE_CLAUSE: "CreateClauseModal",
  CREATE_ENDORSEMENT_WARNING: "CreateEndoWarningModal",
  CREATE_PRODUCT: "CreateProductModal",
  CREATE_SCHEMA: "CreateSchemaModal",
  DEBUGGER_CREATE_TEMPLATE: "DebuggerCreateTemplateModal",
  DEBUGGER_SELECT_TEMPLATE: "DebuggerSelectTemplateModal",
  DECLINE_CONTRACT: "DeclineContractModal",
  DOWNLOAD_BDX: "DownloadBdxModal",
  EDIT_QUOTE_LOCKED_WARNING_MODAL: "EditQuoteLockedWarningModal",
  EDIT_QUOTE_WARNING_MODAL: "EditQuoteWarningModal",
  FLAG_POLICY: "FlagPolicyModal",
  GENERATE_MANUAL_QUOTE: "GenerateManualQuoteModal",
  PROCESS_REFERRAL: "ProcessReferralModal",
  PROVIDE_COMMERCIAL_PRICE: "ProvideCommercialPriceModal",
  REFER_CONTRACT: "ReferContractModal",
  REJECT_NOT_PROGRESSED: "NotProgressedModal",
  REJECT_NOT_TAKEN_UP: "NotTakenUpModal",
  REMOVE_POLICY_FLAG: "RemovePolicyFlagModal",
  SANCTIONS_CHECK_FAIL: "SanctionsCheckFailModal",
  SELECT_POLICY_TYPE: "SelectPolicyTypeModal",
  SUBMIT_BESPOKE_ENDORSEMENT: "SubmitBespokeEndorsementModal",
  SUBMIT_ENDORSEMENT: "SubmitEndorsementModal",
  SUBMIT_RENEWAL_BESPOKE: "SubmitRenewalBespokeModal",
  UNSAVED_CHANGES: "UnsavedChangesModal",
  UPDATE_QUOTE_COMMISSION: "UpdateQuoteCommissionModal",
};

export default MODALS;
