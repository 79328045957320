import { MCPremium, SchemeType } from "../types";

export const getRatesSurgeons = (scheme: SchemeType): MCPremium[] => {
  switch (scheme) {
    case "ISAPS":
      return [
        {
          indemnity: { amount: 100.0, currency: "GBP" },
          premium_exc_ipt: { amount: 4.46, currency: "GBP" },
          ipt: { amount: 0.54, currency: "GBP" },
          premium_inc_ipt: { amount: 5.0, currency: "GBP" },
        },
        {
          indemnity: { amount: 200.0, currency: "GBP" },
          premium_exc_ipt: { amount: 8.93, currency: "GBP" },
          ipt: { amount: 1.07, currency: "GBP" },
          premium_inc_ipt: { amount: 10.0, currency: "GBP" },
        },
        {
          indemnity: { amount: 300.0, currency: "GBP" },
          premium_exc_ipt: { amount: 13.39, currency: "GBP" },
          ipt: { amount: 1.61, currency: "GBP" },
          premium_inc_ipt: { amount: 15.0, currency: "GBP" },
        },
        {
          indemnity: { amount: 400.0, currency: "GBP" },
          premium_exc_ipt: { amount: 17.86, currency: "GBP" },
          ipt: { amount: 2.14, currency: "GBP" },
          premium_inc_ipt: { amount: 20.0, currency: "GBP" },
        },
        {
          indemnity: { amount: 500.0, currency: "GBP" },
          premium_exc_ipt: { amount: 22.33, currency: "GBP" },
          ipt: { amount: 2.68, currency: "GBP" },
          premium_inc_ipt: { amount: 25.0, currency: "GBP" },
        },
        {
          indemnity: { amount: 600.0, currency: "GBP" },
          premium_exc_ipt: { amount: 26.79, currency: "GBP" },
          ipt: { amount: 3.21, currency: "GBP" },
          premium_inc_ipt: { amount: 30.0, currency: "GBP" },
        },
        {
          indemnity: { amount: 700.0, currency: "GBP" },
          premium_exc_ipt: { amount: 31.26, currency: "GBP" },
          ipt: { amount: 3.74, currency: "GBP" },
          premium_inc_ipt: { amount: 35.0, currency: "GBP" },
        },
        {
          indemnity: { amount: 800.0, currency: "GBP" },
          premium_exc_ipt: { amount: 35.72, currency: "GBP" },
          ipt: { amount: 4.28, currency: "GBP" },
          premium_inc_ipt: { amount: 40.0, currency: "GBP" },
        },
        {
          indemnity: { amount: 900.0, currency: "GBP" },
          premium_exc_ipt: { amount: 40.19, currency: "GBP" },
          ipt: { amount: 4.81, currency: "GBP" },
          premium_inc_ipt: { amount: 45.0, currency: "GBP" },
        },
        {
          indemnity: { amount: 1_000.0, currency: "GBP" },
          premium_exc_ipt: { amount: 44.66, currency: "GBP" },
          ipt: { amount: 5.35, currency: "GBP" },
          premium_inc_ipt: { amount: 50.0, currency: "GBP" },
        },
        {
          indemnity: { amount: 1_250.0, currency: "GBP" },
          premium_exc_ipt: { amount: 53.57, currency: "GBP" },
          ipt: { amount: 6.43, currency: "GBP" },
          premium_inc_ipt: { amount: 60.0, currency: "GBP" },
        },
        {
          indemnity: { amount: 2_500.0, currency: "GBP" },
          premium_exc_ipt: { amount: 107.14, currency: "GBP" },
          ipt: { amount: 12.86, currency: "GBP" },
          premium_inc_ipt: { amount: 120.0, currency: "GBP" },
        },
        {
          indemnity: { amount: 3_750.0, currency: "GBP" },
          premium_exc_ipt: { amount: 160.71, currency: "GBP" },
          ipt: { amount: 19.29, currency: "GBP" },
          premium_inc_ipt: { amount: 180.0, currency: "GBP" },
        },
        {
          indemnity: { amount: 5_000.0, currency: "GBP" },
          premium_exc_ipt: { amount: 214.28, currency: "GBP" },
          ipt: { amount: 25.72, currency: "GBP" },
          premium_inc_ipt: { amount: 240.0, currency: "GBP" },
        },
        {
          indemnity: { amount: 6_250.0, currency: "GBP" },
          premium_exc_ipt: { amount: 267.85, currency: "GBP" },
          ipt: { amount: 32.15, currency: "GBP" },
          premium_inc_ipt: { amount: 300.0, currency: "GBP" },
        },
        {
          indemnity: { amount: 7_500.0, currency: "GBP" },
          premium_exc_ipt: { amount: 321.42, currency: "GBP" },
          ipt: { amount: 38.58, currency: "GBP" },
          premium_inc_ipt: { amount: 360.0, currency: "GBP" },
        },
        {
          indemnity: { amount: 8_750.0, currency: "GBP" },
          premium_exc_ipt: { amount: 374.99, currency: "GBP" },
          ipt: { amount: 45.01, currency: "GBP" },
          premium_inc_ipt: { amount: 420.0, currency: "GBP" },
        },
        {
          indemnity: { amount: 10_000.0, currency: "GBP" },
          premium_exc_ipt: { amount: 428.56, currency: "GBP" },
          ipt: { amount: 51.44, currency: "GBP" },
          premium_inc_ipt: { amount: 480.0, currency: "GBP" },
        },
        {
          indemnity: { amount: 11_250.0, currency: "GBP" },
          premium_exc_ipt: { amount: 482.13, currency: "GBP" },
          ipt: { amount: 57.87, currency: "GBP" },
          premium_inc_ipt: { amount: 540.0, currency: "GBP" },
        },
        {
          indemnity: { amount: 12_500.0, currency: "GBP" },
          premium_exc_ipt: { amount: 535.7, currency: "GBP" },
          ipt: { amount: 64.3, currency: "GBP" },
          premium_inc_ipt: { amount: 600.0, currency: "GBP" },
        },
      ];
    case "Commitment Plus":
      return [
        {
          indemnity: { amount: 100.0, currency: "GBP" },
          premium_exc_ipt: { amount: 4.46, currency: "GBP" },
          ipt: { amount: 0.54, currency: "GBP" },
          premium_inc_ipt: { amount: 5.0, currency: "GBP" },
        },
        {
          indemnity: { amount: 200.0, currency: "GBP" },
          premium_exc_ipt: { amount: 8.93, currency: "GBP" },
          ipt: { amount: 1.07, currency: "GBP" },
          premium_inc_ipt: { amount: 10.0, currency: "GBP" },
        },
        {
          indemnity: { amount: 300.0, currency: "GBP" },
          premium_exc_ipt: { amount: 13.39, currency: "GBP" },
          ipt: { amount: 1.61, currency: "GBP" },
          premium_inc_ipt: { amount: 15.0, currency: "GBP" },
        },
        {
          indemnity: { amount: 400.0, currency: "GBP" },
          premium_exc_ipt: { amount: 17.86, currency: "GBP" },
          ipt: { amount: 2.14, currency: "GBP" },
          premium_inc_ipt: { amount: 20.0, currency: "GBP" },
        },
        {
          indemnity: { amount: 500.0, currency: "GBP" },
          premium_exc_ipt: { amount: 22.33, currency: "GBP" },
          ipt: { amount: 2.68, currency: "GBP" },
          premium_inc_ipt: { amount: 25.0, currency: "GBP" },
        },
        {
          indemnity: { amount: 600.0, currency: "GBP" },
          premium_exc_ipt: { amount: 26.79, currency: "GBP" },
          ipt: { amount: 3.21, currency: "GBP" },
          premium_inc_ipt: { amount: 30.0, currency: "GBP" },
        },
        {
          indemnity: { amount: 700.0, currency: "GBP" },
          premium_exc_ipt: { amount: 31.26, currency: "GBP" },
          ipt: { amount: 3.74, currency: "GBP" },
          premium_inc_ipt: { amount: 35.0, currency: "GBP" },
        },
        {
          indemnity: { amount: 800.0, currency: "GBP" },
          premium_exc_ipt: { amount: 35.72, currency: "GBP" },
          ipt: { amount: 4.28, currency: "GBP" },
          premium_inc_ipt: { amount: 40.0, currency: "GBP" },
        },
        {
          indemnity: { amount: 900.0, currency: "GBP" },
          premium_exc_ipt: { amount: 40.19, currency: "GBP" },
          ipt: { amount: 4.81, currency: "GBP" },
          premium_inc_ipt: { amount: 45.0, currency: "GBP" },
        },
        {
          indemnity: { amount: 1_000.0, currency: "GBP" },
          premium_exc_ipt: { amount: 44.66, currency: "GBP" },
          ipt: { amount: 5.35, currency: "GBP" },
          premium_inc_ipt: { amount: 50.0, currency: "GBP" },
        },
        {
          indemnity: { amount: 1_250.0, currency: "GBP" },
          premium_exc_ipt: { amount: 53.57, currency: "GBP" },
          ipt: { amount: 6.43, currency: "GBP" },
          premium_inc_ipt: { amount: 60.0, currency: "GBP" },
        },
        {
          indemnity: { amount: 2_500.0, currency: "GBP" },
          premium_exc_ipt: { amount: 107.14, currency: "GBP" },
          ipt: { amount: 12.86, currency: "GBP" },
          premium_inc_ipt: { amount: 120.0, currency: "GBP" },
        },
        {
          indemnity: { amount: 3_750.0, currency: "GBP" },
          premium_exc_ipt: { amount: 160.71, currency: "GBP" },
          ipt: { amount: 19.29, currency: "GBP" },
          premium_inc_ipt: { amount: 180.0, currency: "GBP" },
        },
        {
          indemnity: { amount: 5_000.0, currency: "GBP" },
          premium_exc_ipt: { amount: 214.28, currency: "GBP" },
          ipt: { amount: 25.72, currency: "GBP" },
          premium_inc_ipt: { amount: 240.0, currency: "GBP" },
        },
        {
          indemnity: { amount: 6_250.0, currency: "GBP" },
          premium_exc_ipt: { amount: 267.85, currency: "GBP" },
          ipt: { amount: 32.15, currency: "GBP" },
          premium_inc_ipt: { amount: 300.0, currency: "GBP" },
        },
        {
          indemnity: { amount: 7_500.0, currency: "GBP" },
          premium_exc_ipt: { amount: 321.42, currency: "GBP" },
          ipt: { amount: 38.58, currency: "GBP" },
          premium_inc_ipt: { amount: 360.0, currency: "GBP" },
        },
        {
          indemnity: { amount: 8_750.0, currency: "GBP" },
          premium_exc_ipt: { amount: 374.99, currency: "GBP" },
          ipt: { amount: 45.01, currency: "GBP" },
          premium_inc_ipt: { amount: 420.0, currency: "GBP" },
        },
        {
          indemnity: { amount: 10_000.0, currency: "GBP" },
          premium_exc_ipt: { amount: 428.56, currency: "GBP" },
          ipt: { amount: 51.44, currency: "GBP" },
          premium_inc_ipt: { amount: 480.0, currency: "GBP" },
        },
        {
          indemnity: { amount: 11_250.0, currency: "GBP" },
          premium_exc_ipt: { amount: 482.13, currency: "GBP" },
          ipt: { amount: 57.87, currency: "GBP" },
          premium_inc_ipt: { amount: 540.0, currency: "GBP" },
        },
        {
          indemnity: { amount: 12_500.0, currency: "GBP" },
          premium_exc_ipt: { amount: 535.7, currency: "GBP" },
          ipt: { amount: 64.3, currency: "GBP" },
          premium_inc_ipt: { amount: 600.0, currency: "GBP" },
        },
      ];
    default:
      throw new Error(`Cannot get rater for surgeons and scheme ${scheme}`);
  }
};
