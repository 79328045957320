import React from "react";
import Accordion from "../../components/Accordion";
import { asCurrency } from "../../../projects/cargo-us/helpers";
import Table from "../../components/Table";

const { Body, Data, Row, Head, Header } = Table;

export const CargoPremium = ({ name, premium }) => {
  return (
    <>
      <div className="border-b border-gray-300">
        <Accordion isSticky>
          <Accordion.Panel key={name} headingText={name}>
            <Table>
              <Body>
                <Row className="border-b border-gray-300 last:border-0">
                  <Data colSpan="5">
                    <div>
                      <Table className="border border-gray-500">
                        <Head className="bg-gray-50">
                          <Header>Gross Premium</Header>
                          <Header>Commission Amount</Header>
                          <Header>Net Premium</Header>
                          <Header>Tax</Header>
                          <Header>Gross Premium plus Tax</Header>
                        </Head>
                        <Body>
                          <Row className="border-b border-gray-300 last:border-0">
                            <Data>{premium.grossPremium !== undefined ? asCurrency(premium.grossPremium) : "N/A"}</Data>
                            <Data>{premium.commissionAmount !== undefined ? asCurrency(premium.commissionAmount) : "N/A"}</Data>
                            <Data>{premium.netPremium !== undefined ? asCurrency(premium.netPremium) : "N/A"}</Data>
                            <Data>{premium.tax !== undefined ? asCurrency(premium.tax) : "N/A"}</Data>
                            <Data>{premium.grossPremiumPlusTax !== undefined ? asCurrency(premium.grossPremiumPlusTax) : "N/A"}</Data>
                          </Row>
                        </Body>
                      </Table>
                    </div>
                  </Data>
                </Row>
              </Body>
            </Table>
          </Accordion.Panel>
        </Accordion>
      </div>
    </>
  );
};
