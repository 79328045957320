export default [
  ["Afghanistan", "AF - Afghanistan", "India and sub continent", "uw_decision", true, true, false],
  [null, "AX - Aland Islands", "Western Europe", false, true, false, false],
  ["Albania", "AL - Albania", "Eastern Europe & Russia West of Urals", false, true, false, false],
  ["Algeria", "DZ - Algeria", "Africa", false, false, false, false],
  [null, "AS - American Samoa", "Asia & Pacific including Australasia", false, true, false, false],
  ["Andorra", "AD - Andorra", "Western Europe", false, true, false, false],
  ["Angola", "AO - Angola", "Africa", true, false, false, false],
  ["Anguilla", "AI - Anguilla", "Caribbean", false, true, false, false],
  [null, "AQ - Antarctica", "Asia & Pacific including Australasia", false, true, false, false],
  ["Antigua & Barbuda", "AG - Antigua & Barbuda", "Caribbean", false, true, false, false],
  ["Argentina", "AR - Argentina", "South & Central America", false, true, false, false],
  ["Armenia", "AM - Armenia", "Former USSR and Russia East of Urals", true, true, false, false],
  ["Aruba", "AW - Aruba", "Caribbean", false, true, false, false],
  ["Australia", "AU - Australia", "Asia & Pacific including Australasia", false, true, false, false],
  ["Austria", "AT - Austria", "Western Europe", false, true, false, false],
  ["Azerbaijan", "AZ - Azerbaijan", "Former USSR and Russia East of Urals", true, true, false, false],
  ["Bahamas", "BS - Bahamas", "Caribbean", false, true, false, false],
  ["Bahrain", "BH - Bahrain", "Middle East", false, true, false, false],
  ["Bangladesh", "BD - Bangladesh", "India and sub continent", false, true, false, false],
  ["Barbados", "BB - Barbados", "Caribbean", false, true, false, false],
  ["Belarus", "BY - Belarus", "Former USSR and Russia East of Urals", false, true, false, true],
  ["Belgium", "BE - Belgium", "Western Europe", false, true, false, false],
  ["Belize", "BZ - Belize", "South & Central America", false, true, false, false],
  ["Benin", "BJ - Benin", "Africa", true, true, false, false],
  ["Bermuda", "BM - Bermuda", "Caribbean", false, true, false, false],
  ["Bhutan", "BT - Bhutan", "India and sub continent", false, true, false, false],
  ["Bolivia", "BO - Bolivia", "South & Central America", false, false, false, false],
  [
    "Bosnia Herzegovina",
    "BA - Bosnia and Herzegovina",
    "Eastern Europe & Russia West of Urals",
    false,
    true,
    false,
    false,
  ],
  ["Botswana", "BW - Botswana", "Africa", true, true, false, false],
  [null, "BV - Bouvet Island", "Asia & Pacific including Australasia", false, true, false, false],
  ["Brazil", "BR - Brazil", "South & Central America", false, true, false, false],
  [null, "IO - British Indian Ocean Territory", "Asia & Pacific including Australasia", false, true, false, false],
  ["British Virgin Islands", "VG - British Virgin Islands", "Caribbean", false, true, false, false],
  ["Brunei", "BN - Brunei Darussalam", "India and sub continent", false, true, false, false],
  ["Bulgaria", "BG - Bulgaria", "Western Europe", false, true, false, false],
  ["Burkina Faso", "BF - Burkina Faso", "Africa", true, false, false, false],
  ["Myanmar (Burma)", "MM - Myanmar", "India and sub continent", false, true, false, false],
  ["Burundi", "BI - Burundi", "Africa", true, false, false, false],
  ["Cambodia", "KH - Cambodia", "India and sub continent", false, true, false, false],
  ["Cameroon", "CM - Cameroon", "Africa", true, false, false, false],
  ["Canada", "CA - Canada", "USA & Canada", false, true, false, false],
  ["Canary Islands", null, "Western Europe", false, true, false, false],
  ["Cape Verde", "CV - Cape Verde", "Western Europe", true, true, false, false],
  ["Cayman Islands", "KY - Cayman Islands", "Caribbean", false, true, false, false],
  ["Central African Rep", "CF - Central African Rep", "Africa", false, false, false, false],
  ["Chad", "TD - Chad", "Africa", true, false, false, false],
  ["Chile", "CL - Chile", "South & Central America", false, true, false, false],
  ["China", "CN - China", "Asia & Pacific including Australasia", false, true, false, false],
  ["Christmas Island", "CX - Christmas Island", "Asia & Pacific including Australasia", false, true, false, false],
  ["Clipperton Island", null, "South & Central America", false, true, false, false],
  [
    "Cocos (Keeling) Islands",
    "CC - Cocos (Keeling) Islands",
    "Asia & Pacific including Australasia",
    false,
    true,
    false,
    false,
  ],
  ["Colombia", "CO - Colombia", "South & Central America", false, false, false, false],
  ["Comoros", "KM - Comoros", "Africa", true, true, false, false],
  ["Congo (Republic of)", "CG - Congo (Brazzaville)", "Africa", false, false, false, false],
  ["Congo DRC", "CD - Congo, (Kinshasa)", "Africa", true, false, false, false],
  ["Cook Islands", "CK - Cook Islands", "Asia & Pacific including Australasia", false, true, false, false],
  ["Costa Rica", "CR - Costa Rica", "South & Central America", false, true, false, false],
  ["Crimea Region and (DPR)", null, "Former USSR and Russia East of Urals", "uw_decision", true, true, false],
  ["Croatia", "HR - Croatia", "Western Europe", false, true, false, false],
  ["Cuba", "CU - Cuba", "South & Central America", null, true, false, true],
  ["Curacao", null, "Caribbean", false, true, false, false],
  ["Cyprus", "CY - Cyprus", "Western Europe", false, true, false, false],
  ["Czech Republic", "CZ - Czech Republic", "Eastern Europe & Russia West of Urals", false, true, false, false],
  ["Denmark", "DK - Denmark", "Western Europe", false, true, false, false],
  ["Djibouti", "DJ - Djibouti", "Africa", true, true, false, false],
  ["Dominica", "DM - Dominica", "Caribbean", false, true, false, false],
  ["Dominican Republic", "DO - Dominican Republic", "Caribbean", false, true, false, false],
  ["Ecuador", "EC - Ecuador", "South & Central America", false, true, false, false],
  ["Eire", "IE - Ireland", "Western Europe", false, true, false, false],
  ["Egypt", "EG - Egypt", "Africa", false, false, false, false],
  ["El Salvador", "SV - El Salvador", "South & Central America", false, true, false, false],
  ["Equatorial Guinea", "GQ - Equatorial Guinea", "Africa", true, true, false, false],
  ["Eritrea/Ethiopia", "ER - Eritrea", "Africa", true, false, false, false],
  ["Estonia", "EE - Estonia", "Eastern Europe & Russia West of Urals", false, true, false, false],
  [
    "Falkland Islands (Islas Malvinas)",
    "FK - Falkland Islands (Malvinas)",
    "Western Europe",
    false,
    true,
    false,
    false,
  ],
  ["Faroe Islands", "FO - Faroe Islands", "Western Europe", false, true, false, false],
  ["Fiji", "FJ - Fiji", "Asia & Pacific including Australasia", false, true, false, false],
  ["Finland", "FI - Finland", "Western Europe", false, true, false, false],
  ["France", "FR - France", "Western Europe", false, true, false, false],
  ["French Guiana", "GF - French Guiana", "South & Central America", false, true, false, false],
  ["French Polynesia", "PF - French Polynesia", "Asia & Pacific including Australasia", false, true, false, false],
  ["Gabon", "GA - Gabon", "Africa", true, true, false, false],
  ["Gaza Strip", "GM - Palestinian Territory", "Middle East", false, false, false, false],
  ["Georgia", "GE - Georgia", "Eastern Europe & Russia West of Urals", true, false, false, false],
  ["Germany", "DE - Germany", "Western Europe", false, true, false, false],
  ["Ghana", "GH - Ghana", "Africa", true, true, false, false],
  ["Gibraltar", "GI - Gibraltar", "Western Europe", false, true, false, false],
  ["Greece", "GR - Greece", "Western Europe", false, true, false, false],
  ["Greenland", "GL - Greenland", "Western Europe", false, true, false, false],
  ["Grenada", "GD - Grenada", "Caribbean", false, true, false, false],
  ["Grenadines", null, "Caribbean", false, true, false, false],
  ["Guadeloupe", "GP - Guadeloupe", "Caribbean", false, true, false, false],
  ["Guam", "GU - Guam", "Asia & Pacific including Australasia", false, true, false, false],
  ["Guatemala", "GT - Guatemala", "South & Central America", false, true, false, false],
  ["Guernsey", "GN - Guernsey", "Western Europe", false, true, false, false],
  ["Guinea", "GN - Guinea", "Africa", true, false, false, false],
  ["Guinea-Bisseau", "GW - Guinea-Bissau", "Africa", true, false, false, false],
  ["Guyana", "GY - Guyana", "South & Central America", false, true, false, false],
  ["Haiti", "HT - Haiti", "South & Central America", true, false, false, false],
  [
    "Heard Island and McDonald Islands",
    "HM - Heard and Mcdonald Islands",
    "Asia & Pacific including Australasia",
    false,
    true,
    false,
    false,
  ],
  ["Holy See (Vatican)", "VA - Holy See (Vatican City State)", "Western Europe", false, true, false, false],
  ["Honduras", "HN - Honduras", "South & Central America", false, true, false, false],
  ["Hong Kong", "HK - Hong Kong, SAR China", "Asia & Pacific including Australasia", false, true, false, false],
  ["Hungary", "HU - Hungary", "Eastern Europe & Russia West of Urals", false, true, false, false],
  ["Iceland", "IS - Iceland", "Western Europe", false, true, false, false],
  ["India", "IN - India", "India and sub continent", false, true, false, false],
  ["Indonesia (Excluding Java)", "ID - Indonesia", "Asia & Pacific including Australasia", false, false, false, false],
  ["Iraq", "IQ - Iraq", "Middle East", "uw_decision", true, true, false],
  ["Iran", "IR - Iran, Islamic Republic of", "Middle East", null, true, false, true],
  ["Ireland (Northern)", null, "Western Europe", false, true, false, false],
  ["Isle of Man", "IM - Isle of Man", "Western Europe", false, true, false, false],
  ["Israel & Palestinian Authority", "IL - Israel", "Middle East", false, false, false, false],
  ["Italy", "IT - Italy", "Western Europe", false, true, false, false],
  ["Ivory Coast", "CI - Côte d'Ivoire", "Africa", true, false, false, false],
  ["Jamaica", "JM - Jamaica", "Caribbean", false, true, false, false],
  ["Jan Mayen", "SJ - Svalbard and Jan Mayen Islands", "Western Europe", false, true, false, false],
  ["Japan", "JP - Japan", "Asia & Pacific including Australasia", false, true, false, false],
  ["Java", null, "Asia & Pacific including Australasia", false, false, false, false],
  ["Jersey", "JE - Jersey", "Western Europe", false, true, false, false],
  ["Jordan", "JO - Jordan", "Middle East", false, true, false, false],
  ["Juan de Nova Island", null, "Africa", true, true, false, false],
  ["Kazakhstan", "KZ - Kazakhstan", "Former USSR and Russia East of Urals", true, true, false, false],
  ["Kenya", "KE - Kenya", "Africa", true, true, false, false],
  [null, "KI - Kiribati", "Asia & Pacific including Australasia", false, true, false, false],
  ["Korea, South", "KR - Korea (South)", "Asia & Pacific including Australasia", false, true, false, false],
  ["Kuwait", "KW - Kuwait", "Middle East", false, true, false, false],
  ["Kyrgyzstan", "KG - Kyrgyzstan", "Former USSR and Russia East of Urals", true, true, false, false],
  ["Laos", "LA - Lao PDR", "India and sub continent", false, true, false, false],
  ["Latvia", "LV - Latvia", "Eastern Europe & Russia West of Urals", false, true, false, false],
  ["Lebanon", "LB - Lebanon", "Middle East", true, false, false, false],
  ["Lesotho", "LS - Lesotho", "Africa", true, false, false, false],
  ["Liberia", "LR - Liberia", "Africa", true, true, false, false],
  ["Libya", "LY - Libya", "Africa", null, true, false, true],
  ["Liechtenstein", "LI - Liechtenstein", "Eastern Europe & Russia West of Urals", false, true, false, false],
  ["Lithuania", "LT - Lithuania", "Eastern Europe & Russia West of Urals", false, true, false, false],
  ["Luxembourg", "LU - Luxembourg", "Western Europe", false, true, false, false],
  ["Macau", "MQ - Macao, SAR China", "India and sub continent", false, true, false, false],
  ["Macedonia", "MK - Macedonia, Republic of", "Eastern Europe & Russia West of Urals", false, true, false, false],
  ["Madagascar", "JO - Madagascar", "Africa", true, true, false, false],
  ["Madeira", null, "Western Europe", false, true, false, false],
  ["Malawi", "MW - Malawi", "Africa", true, true, false, false],
  ["Malaysia", "MY - Malaysia", "Asia & Pacific including Australasia", false, true, false, false],
  ["Maldives", "MV - Maldives", "Asia & Pacific including Australasia", false, true, false, false],
  ["Mali", "ML - Mali", "Africa", true, false, false, false],
  ["Malta", "MT - Malta", "Western Europe", false, true, false, false],
  ["Marshall Islands", "MH - Marshall Islands", "Asia & Pacific including Australasia", false, true, false, false],
  ["Martinique", "MQ - Martinique", "Caribbean", false, true, false, false],
  ["Mauritania", "MR - Mauritania", "Africa", true, true, false, false],
  ["Mauritius", "MU - Mauritius", "Africa", true, true, false, false],
  ["Mayotte", "YT - Mayotte", "Africa", true, true, false, false],
  ["Mexico", "MX - Mexico", "South & Central America", false, true, false, false],
  ["Micronesia", "FM - Micronesia, Federated States of", "India and sub continent", false, true, false, false],
  ["Moldova", "MD - Moldova", "Eastern Europe & Russia West of Urals", true, true, false, false],
  ["Monaco", "MC - Monaco", "Western Europe", false, true, false, false],
  ["Mongolia", "MN - Mongolia", "Former USSR and Russia East of Urals", false, true, false, false],
  ["Montserrat", "MS - Montserrat", "Caribbean", false, true, false, false],
  ["Morocco", "MA - Morocco", "Africa", false, true, false, false],
  ["Mozambique", "MZ - Mozambique", "Africa", true, true, false, false],
  ["Namibia", "NA - Namibia", "Africa", true, true, false, false],
  [null, "NR - Nauru", "Asia & Pacific including Australasia", false, true, false, false],
  ["Nepal", "NP - Nepal", "India and sub continent", null, true, false, true],
  ["Netherlands", "NL - Netherlands", "Western Europe", false, true, false, false],
  ["Netherlands Antilles", "AN - Netherlands Antilles", "Caribbean", false, true, false, false],
  ["New Caledonia", "NC - New Caledonia", "Asia & Pacific including Australasia", false, true, false, false],
  ["New Zealand", "NZ - New Zealand", "Asia & Pacific including Australasia", false, true, false, false],
  ["Nicaragua", "NI - Nicaragua", "South & Central America", false, true, false, false],
  ["Niger", "NE - Niger", "Africa", true, false, false, false],
  ["Nigeria", "NG - Nigeria", "Africa", true, false, false, false],
  [null, "NU - Niue", "Asia & Pacific including Australasia", false, true, false, false],
  ["Norfolk Islands", "NF - Norfolk Island", "Asia & Pacific including Australasia", false, true, false, false],
  ["North Korea", "KP - Korea (North)", "Asia & Pacific including Australasia", null, true, false, true],
  [null, "MP - Northern Mariana Islands", "Asia & Pacific including Australasia", false, true, false, false],
  ["Norway", "NO - Norway", "Western Europe", false, true, false, false],
  ["Oman", "OM - Oman", "Middle East", false, true, false, false],
  ["Pakistan", "PK - Pakistan", "India and sub continent", false, false, false, false],
  ["Palau", "PW - Palau", "Asia & Pacific including Australasia", false, true, false, false],
  ["Panama", "PA - Panama", "South & Central America", false, true, false, false],
  ["Papua New Guinea", "PG - Papua New Guinea", "Asia & Pacific including Australasia", false, true, false, false],
  ["Paracel Islands", null, "Asia & Pacific including Australasia", false, true, false, false],
  ["Paraguay", "PY - Paraguay", "South & Central America", false, true, false, false],
  ["Peru", "PE - Peru", "South & Central America", false, true, false, false],
  ["Philippines", "PH - Philippines", "Asia & Pacific including Australasia", false, false, false, false],
  [null, "PN - Pitcairn", "Asia & Pacific including Australasia", false, true, false, true],
  ["Poland", "PL - Poland", "Eastern Europe & Russia West of Urals", false, true, false, false],
  ["Portugal", null, "Western Europe", false, true, false, false],
  ["Puerto Rico", "PR - Puerto Rico", "South & Central America", false, true, false, false],
  ["Qatar", "QA - Qatar", "Middle East", false, true, false, false],
  ["Reunion", "RE - Réunion", "Africa", false, true, false, false],
  ["Romania", "RO - Romania", "Eastern Europe & Russia West of Urals", false, true, false, false],
  [
    "Russia (Urals and part east of Urals)",
    "RU - Russian Federation",
    "Former USSR and Russia East of Urals",
    true,
    true,
    false,
    false,
  ],
  ["Russia (Western part to Urals only)", null, "Eastern Europe & Russia West of Urals", true, true, false, false],
  ["Rwanda", "RW - Rwanda", "Africa", true, false, false, false],
  [null, "BL - Saint-Barthélemy", "Caribbean", "uw_decision", true, true, false],
  [null, "MF - Saint-Martin (French part)", "Caribbean", false, true, false, false],
  ["Saint Helena", "SH - Saint Helena", "South & Central America", false, true, false, false],
  ["Saint Kitts & Nevis", "KN - Saint Kitts and Nevis", "Caribbean", false, true, false, false],
  ["Saint Lucia", "LC - Saint Lucia", "Caribbean", false, true, false, false],
  [null, "PM - Saint Pierre and Miquelon", "USA & Canada", false, true, false, true],
  ["Saint Vincent", "VC - Saint Vincent and Grenadines", "Caribbean", false, true, false, false],
  ["Samoa", "WS - Samoa", "Asia & Pacific including Australasia", false, true, false, false],
  ["San Marino", "SM - San Marino", "Western Europe", false, true, false, false],
  ["Sao Tome & Principe", "ST - Sao Tome and Principe", "Africa", true, true, false, false],
  ["Saudi Arabia", "SA - Saudi Arabia", "Middle East", false, true, false, false],
  ["Senegal", "SN - Senegal", "Africa", true, true, false, false],
  [
    "Serbia & Montenegro",
    "RS - Serbia and ME - Montenegro",
    "Eastern Europe & Russia West of Urals",
    false,
    true,
    false,
    false,
  ],
  ["Seychelles", "SC - Seychelles", "Africa", false, true, false, false],
  ["Sierra Leone", "SL - Sierra Leone", "Africa", true, false, false, false],
  ["Singapore", "SG - Singapore", "Asia & Pacific including Australasia", false, true, false, false],
  ["Slovakia", "SK - Slovakia", "Eastern Europe & Russia West of Urals", false, true, false, false],
  ["Slovenia", "SI - Slovenia", "Eastern Europe & Russia West of Urals", false, true, false, false],
  ["Soloman Islands", "SB - Soloman Islands", "Asia & Pacific including Australasia", false, true, false, false],
  ["Somalia", "SO - Somalia", "Africa", null, true, false, true],
  ["South Africa", "ZA - South Africa", "Africa", false, true, false, false],
  [
    "South Georgia",
    "GS - South Georgia and the South Sandwich Islands",
    "South & Central America",
    false,
    true,
    false,
    false,
  ],
  ["South Sandwich Islands", null, "South & Central America", false, true, false, false],
  ["Spain", "ES - Spain", "Western Europe", false, true, false, false],
  ["Spratley Islands", null, "Asia & Pacific including Australasia", false, true, false, false],
  ["Sri Lanka", "LK - Sri Lanka", "India and sub continent", false, false, false, false],
  ["Sudan and Sudan South", "SS - Sudan", "Africa", "uw_decision", true, true, false],
  ["Suriname", "SR - Suriname", "South & Central America", false, true, false, false],
  ["Swaziland", "CH - Swaziland", "Africa", true, true, false, false],
  ["Sweden", "SE - Sweden", "Western Europe", false, true, false, false],
  ["Switzerland", "SZ - Switzerland", "Western Europe", false, true, false, false],
  ["Syria", "SY - Syrian Arab Republic (Syria)", "Middle East", "uw_decision", true, true, false],
  ["Taiwan", "TW - Taiwan, Republic of China", "Asia & Pacific including Australasia", false, true, false, false],
  ["Tajikistan", "TJ - Tajikistan", "Former USSR and Russia East of Urals", true, false, false, false],
  ["Tanzania", "TZ - Tanzania, United Republic of", "Africa", true, true, false, false],
  ["Thailand", "TH - Thailand", "Asia & Pacific including Australasia", false, true, false, false],
  ["The Gambia", "GM - Gambia", "Africa", true, true, false, false],
  ["Togo", "TG - Togo", "Africa", true, true, false, false],
  ["Tonga", "TO - Tonga", "Asia & Pacific including Australasia", false, true, false, false],
  ["Trinidad & Tobago", "TT - Trinidad and Tobago", "Caribbean", false, true, false, false],
  ["Tromelin Island", null, "Africa", false, true, false, false],
  ["Tunisia", "TN - Tunisia", "Africa", false, false, false, false],
  ["Turkey", "TR - Turkey", "Middle East", false, true, false, false],
  ["Turkmenistan", "TM - Turkmenistan", "Former USSR and Russia East of Urals", true, false, false, false],
  ["Turks & Caicos Islands", "TC - Turks and Caicos Islands", "Caribbean", false, true, false, false],
  ["Uganda", "UG - Uganda", "Africa", true, true, false, false],
  [
    "Ukraine excluding Crimea Region",
    "UA - Ukraine",
    "Former USSR and Russia East of Urals",
    true,
    false,
    false,
    false,
  ],
  ["United Arab Emirates", "AE - United Arab Emirates", "Middle East", false, true, false, false],
  ["United Kingdom", "GB - United Kingdom", "Western Europe", false, true, false, false],
  ["United States", "US - United States of America", "USA & Canada", false, true, false, false],
  ["Uruguay", "UY - Uruguay", "South & Central America", false, true, false, false],
  ["Uzbekistan", null, "Former USSR and Russia East of Urals", true, true, false, false],
  ["Vanuatu", null, "Asia & Pacific including Australasia", false, true, false, false],
  ["Venezuela", "VE - Venezuela (Bolivarian Republic)", "South & Central America", false, true, false, false],
  ["Vietnam", "VN - Viet Nam", "Asia & Pacific including Australasia", false, true, false, false],
  ["Virgin Islands", "VI - Virgin Islands, US", "Caribbean", false, true, false, false],
  ["West Bank (Palestinian)", null, "Middle East", false, true, false, false],
  ["Western Sahara", "EH - Western Sahara", "Africa", true, true, false, false],
  ["Yemen", "YE - Yemen", "Middle East", null, true, false, true],
  ["Zaire", null, "Africa", "uw_decision", true, true, false],
  ["Zambia", "ZM - Zambia", "Africa", true, true, false, false],
  ["Zimbabwe", "ZW - Zimbabwe", "Africa", null, true, false, true],
  [null, "TL - Timor-Leste", "Asia & Pacific including Australasia", null, true, null, null],
  [null, "TV - Tuvalu", "Asia & Pacific including Australasia", null, true, null, null],
  [null, "UM - US Minor Outlying Islands", "Asia & Pacific including Australasia", null, true, null, null],
  [null, "WF - Wallis and Futuna Islands", "Asia & Pacific including Australasia", null, true, null, null],
] as const;
