import get from "lodash.get";
import set from "lodash.set";
import unset from "lodash.unset";
import cloneDeep from "lodash.clonedeep";
import moment from "moment";
import { compile } from "path-to-regexp";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory, useLocation, useParams, useRouteMatch } from "react-router-dom";
import { Product, ProductRef, SchemaRef } from "../../@types/types";
import { ROUTES, STATUSES } from "../../constants";
import { parseParams } from "../../helpers/parseParams";
import * as api from "../api";
import useAuth from "./useAuth";
import { CONTRACT_TYPES } from "../../constants/contractTypes";

const prepareContractData = (contractData: any, isRenewalRoute: any) => {
  // https://trello.com/c/gludoqcz/186-producing-broker-logic-change
  // if (Object.keys(contractData).length === 0 && tenant.type === "producing-broker") {
  //   const contractDataForProducing = cloneDeep(contractData);
  //   set(contractDataForProducing, "submission.broker_information.has_producing_agent", "Yes");
  //   set(contractDataForProducing, "submission.broker_information.producing_agent_id", tenant.id);
  //   return contractDataForProducing;
  // }

  if (isRenewalRoute && process.env.DEFAULT_PRODUCT_REF === "mel") {
    const contractDataForRenewal = cloneDeep(contractData);
    set(
      contractDataForRenewal,
      "submission.quote.inception_date",
      moment(get(contractDataForRenewal, "submission.quote.expiry_date")).toISOString(),
    );
    set(
      contractDataForRenewal,
      "submission.quote.expiry_date",
      moment(get(contractDataForRenewal, "submission.quote.inception_date")).add(1, "year").toISOString(),
    );
    unset(contractDataForRenewal, "submission.payroll");
    unset(contractDataForRenewal, "submission.rigs.rigs_breakdown");
    unset(contractDataForRenewal, "submission.rigs.insured_will_not_undertake_work_on_platforms");
    unset(contractDataForRenewal, "submission.loss_record.has_insured_had_jones_act_claims");
    unset(contractDataForRenewal, "submission.current_insurance.does_the_insured_currently_have_insurance");

    return contractDataForRenewal;
  }

  return contractData;
};

export const useContractQuery = (args?: { endorsementId?: string }) => {
  const { search } = useLocation<{ schemaRef: SchemaRef }>();
  const queryClient = useQueryClient();
  const isNewContractRoute = useRouteMatch(ROUTES.CONTRACT_NEW);
  const isEditContractRoute = useRouteMatch(ROUTES.CONTRACT_EDIT);
  const isNewBespokeEndorsementRoute = useRouteMatch(ROUTES.ENDORSEMENT_BESPOKE_NEW);
  const isNewEndorsementRoute = useRouteMatch(ROUTES.ENDORSEMENT_NEW) || isNewBespokeEndorsementRoute;
  const isEditEndorsementRoute = useRouteMatch(ROUTES.ENDORSEMENT_EDIT);
  const isRenewalRoute = useRouteMatch(ROUTES.RENEWAL);

  const { push, location } = useHistory();
  const params = useParams<{ productRef: ProductRef; contractId: string; endorsementId: string }>();
  const queryParams = parseParams(location.search);
  const { productRef, contractId } = params;
  const endorsementId = (args && args.endorsementId) || queryParams.endorsementId || params.endorsementId;
  const enabled = Boolean(contractId);

  const productQuery = useQuery(["product", { productRef }], api.getProduct);
  const productData = (productQuery?.data?.data?.data || {}) as Product;

  const cKey = ["contract", { productRef, contractId }];
  const contractQuery = useQuery(cKey, api.getContract, { enabled });
  const contractData = contractQuery?.data?.data?.data || {};
  const parentKey = ["contract", { productRef, contractId: contractData?.renewedFrom }];
  const parentContractQuery = useQuery(parentKey, api.getContract, { enabled });
  const parentContractData = parentContractQuery?.data?.data?.data || {};
  const isRenewal = contractData?.type === CONTRACT_TYPES.RENEWAL;

  const schemaRef =
    parseParams(search)?.schemaRef ||
    productData?.schemas?.find((schema) => schema?.id === contractData.schemaId)?.ref ||
    productData?.schemas?.[0]?.ref;

  const schemaId =
    contractData?.schemaId ||
    productData?.schemas?.find((schema) => schema?.ref === schemaRef)?.id ||
    productData?.schemas?.[0]?.id;
  const schemaQuery = useQuery(["schema", { schemaId }], api.getSchema, { enabled: Boolean(schemaId) });
  const schemaData = schemaQuery?.data?.data?.data?.payload || {};

  const endoKey = ["endorsement", { productRef, contractId, endorsementId }];
  const endoQuery = useQuery(endoKey, api.getEndorsement, { enabled: Boolean(contractId && endorsementId) });
  const endorsementData = endoQuery?.data?.data?.data;

  const endosKey = ["endorsements", { productRef, contractId }];
  const endorsementsQuery = useQuery(endosKey, api.getEndorsements, { enabled });
  const endorsementsData = endorsementsQuery?.data?.data?.data || [];

  const sKey = ["snapshot", { productRef, contractId }];
  const snapshotQuery = useQuery(sKey, api.getSnapshots, { enabled });
  const snapshotData = snapshotQuery?.data?.data?.data || [];

  const cclsKey = ["contractClauses", { productRef, contractId }];
  const clauseQuery = useQuery(cclsKey, api.getContractClauses, { enabled });
  const clauseData = clauseQuery?.data?.data?.data || [];

  const auKey = ["audit", { productRef, contractId }];
  const auditQuery = useQuery(auKey, api.getAudit, { enabled });
  const auditData = auditQuery?.data?.data?.data || [];

  const { mutateAsync: createContract, ...createContractQuery } = useMutation(api.createContract, {
    onSuccess: () => {
      queryClient.invalidateQueries("contracts", { productRef });
    },
  });

  const { mutateAsync: updateContract, ...updateContractQuery } = useMutation(api.updateContract, {
    onSuccess: (data) => {
      queryClient.setQueryData(["contract", { productRef, contractId }], data);
      queryClient.invalidateQueries("audit", { productRef, contractId });
      queryClient.invalidateQueries("contracts", { productRef });
      queryClient.invalidateQueries("endorsements", { productRef, contractId });
      setTimeout(() => {
        queryClient.resetQueries("documents", { productRef, contractId });
      }, 2000);
      setTimeout(() => {
        queryClient.invalidateQueries("snapshot", { productRef, contractId });
      }, 500);
    },
  });

  const { mutateAsync: createEndorsement, ...createEndorsementQuery } = useMutation(api.createEndorsement, {
    onSuccess: () => {
      queryClient.invalidateQueries("contract", { productRef, contractId });
    },
  });

  const { mutateAsync: updateEndorsement, ...updateEndorsementQuery } = useMutation(api.updateEndorsement, {
    onSuccess: (data) => {
      queryClient.setQueryData(["endorsement", { productRef, contractId, endorsementId }], data);
      queryClient.invalidateQueries("audit", { productRef, contractId });
      queryClient.invalidateQueries("contract", { productRef, contractId });
      queryClient.invalidateQueries("contracts", { productRef });
      queryClient.invalidateQueries("endorsements", { productRef, contractId });
      setTimeout(() => {
        queryClient.resetQueries("documents", { productRef, contractId, endorsementId });
      }, 2000);
      setTimeout(() => {
        queryClient.invalidateQueries("snapshot", { productRef, contractId });
      }, 500);
    },
  });

  const isLoading =
    auditQuery.isLoading ||
    clauseQuery.isLoading ||
    contractQuery.isLoading ||
    endoQuery.isLoading ||
    endorsementsQuery.isLoading ||
    productQuery.isLoading ||
    schemaQuery.isLoading ||
    snapshotQuery.isLoading;

  const { tenant } = useAuth();

  const currentContractData = endorsementId
    ? prepareContractData(endorsementData, isRenewalRoute)
    : prepareContractData(contractData, isRenewalRoute);

  const is404 = get(contractQuery, "error.response.status") === 404;
  const isDraft = currentContractData?.status === STATUSES.DRAFT;
  const isCreating = createContractQuery.isLoading || createEndorsementQuery.isLoading;
  const isUpdating = updateContractQuery.isLoading || updateEndorsementQuery.isLoading;
  const hasEndorsements = endorsementsData.length > 0;
  // TODO: cargo-us requirement, handle via permissions
  const hasActiveClaim = currentContractData?.flags?.activeClaim;
  const isEndorsement = Boolean(queryParams.endorsementId);
  const endoStatus = endorsementsData?.[0]?.status;

  const endoFinalStates = [
    STATUSES.APPLIED,
    STATUSES.REJECTED_NOT_TAKEN_UP,
    STATUSES.REJECTED_NOT_PROGRESSED,
    STATUSES.EXPIRED,
    STATUSES.DECLINED_EXPIRED,
  ];

  const canCreateEndorsements =
    !hasActiveClaim && (!hasEndorsements || (hasEndorsements && endoFinalStates.includes(endoStatus)));

  const canCreateRenewal = !contractData?.renewals?.length;

  const hasActiveEndorsements = hasEndorsements && !endoFinalStates.includes(endoStatus);

  if (productRef !== process.env.DEFAULT_PRODUCT_REF) {
    return push(compile(ROUTES.PRODUCT)({ productRef: process.env.DEFAULT_PRODUCT_REF }));
  }

  if (is404) {
    return push(compile(ROUTES.CONTRACT_NOT_FOUND)({ productRef }));
  }

  return {
    auditData,
    canCreateEndorsements,
    canCreateRenewal,
    clauseData,
    contractData,
    parentContractData,
    contractId,
    createContract: !contractId || isRenewalRoute ? createContract : createEndorsement,
    currentContractData,
    endorsementData,
    endorsementId,
    endorsementsData,
    hasActiveEndorsements,
    hasEndorsements,
    isCreating,
    isDraft,
    isEditContractRoute,
    isEditEndorsementRoute,
    isEndorsement,
    isLoading,
    isNewBespokeEndorsementRoute,
    isNewContractRoute,
    isNewEndorsementRoute,
    isRenewalRoute,
    isRenewal,
    isUpdating,
    productData,
    productRef,
    schemaData,
    schemaId,
    schemaRef,
    snapshotData,
    updateContract: endorsementId ? updateEndorsement : updateContract,
  };
};

export default useContractQuery;
