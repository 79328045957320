import { MCPremium, SchemeType } from "../types";

export const getRatesHospitalsAndClinics = (scheme: SchemeType): MCPremium[] => {
  switch (scheme) {
    case "McIndoe":
      return [
        {
          band: "A",
          indemnity: { amount: 1_250.0, currency: "GBP" },
          premium_exc_ipt: { amount: 60.02, currency: "GBP" },
          ipt: { amount: 7.2, currency: "GBP" },
          premium_inc_ipt: { amount: 67.22, currency: "GBP" },
        },
        {
          band: "B",
          indemnity: { amount: 2_500.0, currency: "GBP" },
          premium_exc_ipt: { amount: 120.04, currency: "GBP" },
          ipt: { amount: 14.4, currency: "GBP" },
          premium_inc_ipt: { amount: 134.44, currency: "GBP" },
        },
        {
          band: "C",
          indemnity: { amount: 3_750.0, currency: "GBP" },
          premium_exc_ipt: { amount: 180.05, currency: "GBP" },
          ipt: { amount: 21.61, currency: "GBP" },
          premium_inc_ipt: { amount: 201.66, currency: "GBP" },
        },
        {
          band: "D",
          indemnity: { amount: 5_000.0, currency: "GBP" },
          premium_exc_ipt: { amount: 240.07, currency: "GBP" },
          ipt: { amount: 28.81, currency: "GBP" },
          premium_inc_ipt: { amount: 268.88, currency: "GBP" },
        },
        {
          band: "E",
          indemnity: { amount: 6_250.0, currency: "GBP" },
          premium_exc_ipt: { amount: 300.09, currency: "GBP" },
          ipt: { amount: 36.01, currency: "GBP" },
          premium_inc_ipt: { amount: 336.1, currency: "GBP" },
        },
        {
          band: "F",
          indemnity: { amount: 7_500.0, currency: "GBP" },
          premium_exc_ipt: { amount: 360.11, currency: "GBP" },
          ipt: { amount: 43.21, currency: "GBP" },
          premium_inc_ipt: { amount: 403.32, currency: "GBP" },
        },
        {
          band: "W",
          indemnity: { amount: 500.0, currency: "GBP" },
          premium_exc_ipt: { amount: 24.01, currency: "GBP" },
          ipt: { amount: 2.88, currency: "GBP" },
          premium_inc_ipt: { amount: 26.89, currency: "GBP" },
        },
        {
          band: "X",
          indemnity: { amount: 375.0, currency: "GBP" },
          premium_exc_ipt: { amount: 18.01, currency: "GBP" },
          ipt: { amount: 2.16, currency: "GBP" },
          premium_inc_ipt: { amount: 20.17, currency: "GBP" },
        },
        {
          band: "Y",
          indemnity: { amount: 250.0, currency: "GBP" },
          premium_exc_ipt: { amount: 12.0, currency: "GBP" },
          ipt: { amount: 1.44, currency: "GBP" },
          premium_inc_ipt: { amount: 13.44, currency: "GBP" },
        },
        {
          band: "Z",
          indemnity: { amount: 125.0, currency: "GBP" },
          premium_exc_ipt: { amount: 6.01, currency: "GBP" },
          ipt: { amount: 0.72, currency: "GBP" },
          premium_inc_ipt: { amount: 6.73, currency: "GBP" },
        },
      ];
    case "Healthier Weight":
      return [
        {
          indemnity: { amount: 2250.0, currency: "GBP" },
          premium_exc_ipt: { amount: 111.61, currency: "GBP" },
          ipt: { amount: 13.39, currency: "GBP" },
          premium_inc_ipt: { amount: 125.0, currency: "GBP" },
        },
      ];
    case "THMG":
      return [
        {
          indemnity: { amount: 3500, currency: "GBP" },
          premium_exc_ipt: { amount: 156.25, currency: "GBP" },
          ipt: { amount: 18.75, currency: "GBP" },
          premium_inc_ipt: { amount: 175.0, currency: "GBP" },
        },
      ];
    case "Transform & THG": {
      return [
        {
          indemnity: { amount: 5_000.0, currency: "GBP" },
          premium_exc_ipt: { amount: 223.22, currency: "GBP" },
          ipt: { amount: 26.78, currency: "GBP" },
          premium_inc_ipt: { amount: 250.0, currency: "GBP" },
        },
      ];
    }
    case "Red Rose Desire": {
      return [
        {
          indemnity: { amount: 1000.0, currency: "GBP" },
          premium_exc_ipt: { amount: 42.86, currency: "GBP" },
          ipt: { amount: 5.14, currency: "GBP" },
          premium_inc_ipt: { amount: 48.0, currency: "GBP" },
        },
        {
          indemnity: { amount: 2000.0, currency: "GBP" },
          premium_exc_ipt: { amount: 85.71, currency: "GBP" },
          ipt: { amount: 10.29, currency: "GBP" },
          premium_inc_ipt: { amount: 96.0, currency: "GBP" },
        },
        {
          indemnity: { amount: 3000.0, currency: "GBP" },
          premium_exc_ipt: { amount: 128.57, currency: "GBP" },
          ipt: { amount: 15.43, currency: "GBP" },
          premium_inc_ipt: { amount: 144.0, currency: "GBP" },
        },
        {
          indemnity: { amount: 4000.0, currency: "GBP" },
          premium_exc_ipt: { amount: 171.43, currency: "GBP" },
          ipt: { amount: 20.57, currency: "GBP" },
          premium_inc_ipt: { amount: 192.0, currency: "GBP" },
        },
        {
          indemnity: { amount: 5000.0, currency: "GBP" },
          premium_exc_ipt: { amount: 214.29, currency: "GBP" },
          ipt: { amount: 25.71, currency: "GBP" },
          premium_inc_ipt: { amount: 240.0, currency: "GBP" },
        },
        {
          indemnity: { amount: 6000.0, currency: "GBP" },
          premium_exc_ipt: { amount: 257.14, currency: "GBP" },
          ipt: { amount: 30.86, currency: "GBP" },
          premium_inc_ipt: { amount: 288.0, currency: "GBP" },
        },
        {
          indemnity: { amount: 7000.0, currency: "GBP" },
          premium_exc_ipt: { amount: 300.0, currency: "GBP" },
          ipt: { amount: 36.0, currency: "GBP" },
          premium_inc_ipt: { amount: 336.0, currency: "GBP" },
        },
        {
          indemnity: { amount: 8000.0, currency: "GBP" },
          premium_exc_ipt: { amount: 342.86, currency: "GBP" },
          ipt: { amount: 41.14, currency: "GBP" },
          premium_inc_ipt: { amount: 384.0, currency: "GBP" },
        },
        {
          indemnity: { amount: 9000.0, currency: "GBP" },
          premium_exc_ipt: { amount: 385.71, currency: "GBP" },
          ipt: { amount: 46.29, currency: "GBP" },
          premium_inc_ipt: { amount: 432.0, currency: "GBP" },
        },
        {
          indemnity: { amount: 10000.0, currency: "GBP" },
          premium_exc_ipt: { amount: 428.57, currency: "GBP" },
          ipt: { amount: 51.43, currency: "GBP" },
          premium_inc_ipt: { amount: 480.0, currency: "GBP" },
        },
      ];
    }
    default:
      throw new Error(`Cannot get rater for hospitals and clinics and scheme ${scheme}`);
  }
};
