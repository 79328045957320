import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useState } from "react";

const Svg = ({ stroke, strokeWidth, fill }) => (
  <svg width="24" height="24">
    <rect
      fill="#fff"
      height="22"
      rx="1.5"
      stroke={stroke}
      strokeWidth={strokeWidth}
      width="22"
      x="1"
      y="1"
    />
    <path
      fill={fill}
      d="M9.812 14.415l-2.667-2.797-.18-.19-.182.19-.964 1.011-.164.173.164.172 3.812 3.999.181.19.18-.19 8.189-8.589.164-.172-.164-.173-.964-1.011-.181-.19-.181.19-7.043 7.387z"
    />
  </svg>
);

const InputCheckbox = ({
  className,
  descriptionText,
  id,
  isChecked,
  isDisabled,
  labelText,
  name,
  value,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);
  const stroke = (isDisabled && "#CED4DA") || (isFocused && "#339AF0") || "#868E96";
  const strokeWidth = isFocused ? "2" : "1";
  const fill =
    (isChecked && !isDisabled && "#339AF0") || (isChecked && isDisabled && "#CED4DA") || "none";

  return (
    <label
      className={clsx(
        "relative flex items-start cursor-pointer",
        isDisabled && "text-gray-700",
        className
      )}
      htmlFor={id}
    >
      <Svg stroke={stroke} strokeWidth={strokeWidth} fill={fill} />

      <div className="ml-3">
        <div>{labelText}</div>
        {descriptionText && (
          <p className={clsx("text-sm mt-2", !isDisabled && "text-gray-800")}>{descriptionText}</p>
        )}
      </div>

      <input
        checked={Boolean(isChecked)}
        className="absolute opacity-0 -z-10"
        disabled={isDisabled}
        id={id}
        name={name}
        onBlur={handleBlur}
        onFocus={handleFocus}
        type="checkbox"
        value={value}
        {...props}
      />
    </label>
  );
};

Svg.propTypes = {
  fill: PropTypes.string,
  stroke: PropTypes.string,
  strokeWidth: PropTypes.string,
};

Svg.defaultProps = {
  fill: undefined,
  stroke: undefined,
  strokeWidth: undefined,
};

InputCheckbox.propTypes = {
  className: PropTypes.string,
  descriptionText: PropTypes.string,
  id: PropTypes.string,
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  labelText: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  name: PropTypes.string,
  value: PropTypes.string,
};

InputCheckbox.defaultProps = {
  className: undefined,
  descriptionText: undefined,
  id: undefined,
  isChecked: undefined,
  isDisabled: undefined,
  labelText: undefined,
  name: undefined,
  value: undefined,
};

export default InputCheckbox;
