import cloneDeep from "lodash.clonedeep";
import set from "lodash.set";
import moment from "moment";
import { useEffect } from "react";
import { SchemaRef } from "../../@types/types";
import { getRates } from "./rater/rater";

export const useMCEffects = (formValues: any, setFormValues: any): void => {
  useEffect(() => {
    if (formValues?.base?.inception_date) {
      const newExpiryDate = moment(formValues?.base?.inception_date).add(364, "days").toISOString();
      const newRenewalReminderDate = moment(newExpiryDate).subtract(1, "month").toISOString();

      const nextFormValues = cloneDeep(formValues);

      set(nextFormValues, "base.expiry_date", newExpiryDate);
      set(nextFormValues, "base.renewal_reminder", newRenewalReminderDate);
      setFormValues(nextFormValues);
    }
  }, [formValues?.base?.inception_date]);

  useEffect(() => {
    if (formValues?.base?.address?.country) {
      const country = formValues?.base?.address?.country;

      const nextFormValues = cloneDeep(formValues);

      set(nextFormValues, "base.country", country);
      setFormValues(nextFormValues);
    }
  }, [formValues?.base?.address?.country]);

  useEffect(() => {
    if (formValues?.base?.scheme_type) {
      let schemaRef: SchemaRef | undefined = undefined;
      if (formValues?.base?.contact_name) {
        schemaRef = "sure-mc-hospitals-and-clinics";
      }
      if (formValues?.base?.title) {
        schemaRef = "sure-mc-surgeons";
      }

      let rates = [];
      if (schemaRef) {
        rates = getRates(schemaRef, formValues?.base?.scheme_type);
      } else {
        rates = formValues?.rates ?? [];
      }

      const nextFormValues = cloneDeep(formValues);

      set(nextFormValues, "rates", rates);
      setFormValues(nextFormValues);
    }
  }, [formValues?.base?.scheme_type]);
};
