import { mergeWith } from "lodash";
import cloneDeep from "lodash.clonedeep";
import get from "lodash.get";
import { compile } from "path-to-regexp";
import PropTypes from "prop-types";
import React, { memo, useState } from "react";
import { useMutation } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { ACTIONS, MODALS, ROUTES, STATUSES } from "../../../constants";
import { prepareContractDetails } from "../../../helpers/prepareContractDetails";
import * as api from "../../api";
import Accordion from "../../components/Accordion";
import Button from "../../components/Button";
import H3 from "../../components/H3";
import Table from "../../components/Table";
import Toggle from "../../components/Toggle";
import { downloadDocument } from "../../helpers/downloadDocument";
import { useAuth } from "../../hooks";
import useModal from "../../hooks/useModal";
import { useMutateContractQuery } from "../../hooks/useMutateContractQuery";
import AccordionPanelBespoke from "./AccordionPanelBespoke";
import AccordionPanelClauses from "./AccordionPanelClauses";
import AccordionPanelDocuments from "./AccordionPanelDocuments";
import Banners from "./Banners";
import Datapoint from "./Datapoint";
import EndoCancelReferralDecision from "./EndoCancelReferralDecision";
import EndoReferralDecision from "./EndoReferralDecision";
import FlagPolicy from "./FlagPolicy";
import QuoteBanners from "./QuoteBanners";
import Quotes from "./Quotes";
import ReferralDecision from "./ReferralDecision";
import { CargoPremiums } from "../ContractDetails/CargoPremiums";
import { v4 as uuid } from "uuid";

const { Body, Data, Row, Head, Header } = Table;

const ContractDetails = ({
  allowChangesToggle,
  allowHistoryTooltip,
  clauseData,
  contractData,
  defaultShowChangesOnly,
  isCurrent,
  isEndorsement,
  schemaData,
  snapshotData,
}) => {
  const { push } = useHistory();
  const [changesOnly, setChangesOnly] = useState(defaultShowChangesOnly);
  const { showModal, closeModal } = useModal();
  const { mutateAsync: getAttachmentLink, ...getAttachmentLinkQuery } = useMutation(api.getAttachmentLink);
  const { updateContract, isUpdating } = useMutateContractQuery({});

  const { checkPermissions, isProducingBroker, isBroker, isLocalEnv } = useAuth(contractData);
  const { contractId, productRef } = useParams();
  const bespokeEndos = get(contractData, "bespoke_endorsements", []);
  const hasBespokeEndos = bespokeEndos.length > 0;
  const endorsementId = isEndorsement ? contractData.id : undefined;
  const referralReasons = get(contractData, "referralReasons", []);
  const isReferred = contractData.status === STATUSES.REFERRED;
  const isReferredCancellation = referralReasons.includes("Known losses.");
  const canCancelManual =
    isCurrent && isEndorsement && isReferredCancellation && checkPermissions(ACTIONS.CANCEL_ENDORSEMENT_MANUAL);

  const canCreateEndoQuote =
    isCurrent && isEndorsement && !isReferredCancellation && checkPermissions(ACTIONS.PROVIDE_COMMERCIAL_PRICE);

  const hasBoundQuote = contractData?.boundQuote !== undefined ? true : false;

  const clonedSchema = cloneDeep(schemaData);

  mergeWith(
    clonedSchema?.properties?.SubmissionForm?.properties?.quote?.properties,
    clonedSchema?.properties?.EndorsementSubmissionForm?.properties,
  );

  const result = prepareContractDetails({
    contract: contractData,
    prevContract: isEndorsement ? contractData.parentSnapshot : snapshotData?.[snapshotData?.length - 2],
    snapshots: snapshotData,
    schema: clonedSchema,
    changesOnly,
    generateHistory: allowHistoryTooltip,
  });

  const handleDownloadClick = async ({ documentId }) => {
    const res = await getAttachmentLink({ contractId, productRef, documentId });
    const { url, fileName } = res?.data?.data;

    downloadDocument(url, fileName);
  };

  const handleActions = (action) => {
    if ([ACTIONS.EDIT_CLAIM_FLAG, ACTIONS.EDIT_PREMIUM_PAID_FLAG].includes(action.type) && !action.isChecked) {
      return showModal(MODALS.REMOVE_POLICY_FLAG, {
        handleConfirm: async () => {
          await updateContract({ productRef, contractId, data: { type: action.type } });

          closeModal();
        },
      });
    }

    if ([ACTIONS.EDIT_CLAIM_FLAG, ACTIONS.EDIT_PREMIUM_PAID_FLAG].includes(action.type) && action.isChecked) {
      return showModal(MODALS.FLAG_POLICY, {
        handleConfirm: async () => {
          await updateContract({ productRef, contractId, data: { type: action.type } });

          closeModal();
        },
      });
    }

    if (action.type === ACTIONS.CREATE_CLAUSE) {
      return showModal(MODALS.CREATE_CLAUSE, { endorsementId });
    }

    if (action.type === ACTIONS.EDIT_CLAUSE) {
      return showModal(MODALS.CLAUSE_EDIT, { clause: action.clause, endorsementId, contractData });
    }

    if (action.type === ACTIONS.EDIT_BESPOKE) {
      return showModal(MODALS.BESPOKE_EDIT, { bespoke: action.bespoke, endorsementId, contractData });
    }

    return false;
  };

  const handleConfirmEdit = () => {
    closeModal();

    if (isEndorsement) {
      return push(compile(ROUTES.ENDORSEMENT_EDIT)({ productRef, contractId, endorsementId }));
    }

    return push(compile(ROUTES.CONTRACT_EDIT)({ contractId, productRef }));
  };

  const createRenewalFunc = async () => {
    const newContractId = uuid().toString();

    await updateContract({
      productRef,
      contractId,
      data: { type: ACTIONS.CREATE_RENEWAL, payload: { newContractId } },
    });

    return newContractId;
  };

  const createRenewalMutation = useMutation(() => createRenewalFunc(), {
    onSuccess: (newContractId) => {
      return push(compile(ROUTES.CONTRACT_EDIT)({ productRef, contractId: newContractId }));
    },
  });

  const handleCreateRenewalForSure = async () => {
    createRenewalMutation.mutate();
  };

  const recreatePdfFunc = async () => {
    await updateContract({
      productRef,
      contractId,
      data: { type: "Recreate pdf", payload: {} },
    });
  };

  const recreatePdfMutation = useMutation(() => recreatePdfFunc(), {
    onSuccess: () => {
      //
    },
  });

  const handleRecreatePdf = async () => {
    recreatePdfMutation.mutate();
  };

  return (
    <>
      <Banners contractData={contractData} />

      <div className="mb-4 xl:flex xl:-mx-3">
        <div className="bg-white rounded shadow p-4 mb-8 xl:w-1/2 xl:mx-3">
          <div className="flex items-center justify-between mb-4">
            <div>
              <H3>Policy details</H3>

              {allowChangesToggle && (
                <Toggle
                  className="mt-2"
                  onClick={() => setChangesOnly(!changesOnly)}
                  value={changesOnly}
                  labelText="Show changes only"
                />
              )}
            </div>

            {isCurrent && checkPermissions(ACTIONS.UPDATE_SUBMISSION) && (
              <Button
                iconName="create"
                className="px-3 h-10"
                onClick={() => showModal(MODALS.EDIT_QUOTE_WARNING_MODAL, { handleConfirm: handleConfirmEdit })}
              >
                Edit submission
              </Button>
            )}

            {isCurrent && !checkPermissions(ACTIONS.UPDATE_SUBMISSION) && isReferred && (
              <Button
                iconName="lock2"
                className="px-3 h-10"
                onClick={() => showModal(MODALS.EDIT_QUOTE_LOCKED_WARNING_MODAL)}
              >
                Edit submission
              </Button>
            )}

            {isCurrent && process.env.DEFAULT_PRODUCT_REF === "mc" && checkPermissions(ACTIONS.CREATE_RENEWAL) && (
              <Button
                iconName="create"
                className="px-3 h-10"
                onClick={() => handleCreateRenewalForSure()}
                isDisabled={createRenewalMutation.isLoading}
              >
                Create Renewal
              </Button>
            )}

            {isCurrent && process.env.DEFAULT_PRODUCT_REF === "mc" && isLocalEnv && (
              <Button
                iconName="create"
                className="px-3 h-10"
                onClick={() => handleRecreatePdf()}
                isDisabled={recreatePdfMutation.isLoading}
              >
                Recreate pdf
              </Button>
            )}
          </div>

          <Accordion id={contractId} isSticky>
            <AccordionPanelDocuments
              key={`documents-${contractData.status ?? ""}`}
              headingText="Documents"
              contractData={contractData}
              endorsementId={endorsementId}
            />

            {result
              .filter((section) => section.data.length > 0)
              .map((section) => (
                <Accordion.Panel key={section.sectionKey} headingText={section.sectionTitle}>
                  <Table>
                    <Body>
                      {section.data.map((datapoint) => {
                        if (
                          (datapoint.datapointComponent === "SectionRepeater" ||
                            datapoint.datapointComponent === "ControlledSectionRepeater" ||
                            datapoint.datapointComponent === "InputMatrix") &&
                          datapoint.datapointLayout === "vertical"
                        ) {
                          return datapoint.rows.map((row, rowIndex) => (
                            <Row key={datapoint.datapointKey} className="border-b border-gray-300 last:border-0">
                              <Data colSpan="2">
                                <div>
                                  <p className="mb-3 font-medium">
                                    {datapoint.datapointTitle} #{rowIndex + 1}
                                  </p>
                                  <Table className="border border-gray-500">
                                    <Body>
                                      {row.map((data, columnIndex) => (
                                        <Row
                                          key={columnIndex.toString()}
                                          className="border-b border-gray-300 last:border-0"
                                        >
                                          <Data>{datapoint.columns[columnIndex].title}</Data>
                                          <Data key={columnIndex.toString()}>
                                            <Datapoint
                                              allowChangesToggle={allowChangesToggle}
                                              allowHistoryTooltip={allowHistoryTooltip}
                                              data={data}
                                            />
                                          </Data>
                                        </Row>
                                      ))}
                                    </Body>
                                  </Table>
                                </div>
                              </Data>
                            </Row>
                          ));
                        }

                        if (
                          datapoint.datapointComponent === "SectionRepeater" ||
                          datapoint.datapointComponent === "ControlledSectionRepeater" ||
                          datapoint.datapointComponent === "InputMatrix"
                        ) {
                          return (
                            <Row key={datapoint.datapointKey} className="border-b border-gray-300 last:border-0">
                              <Data colSpan="2">
                                <div>
                                  <Table className="border border-gray-500">
                                    <Head className="bg-gray-50">
                                      {datapoint.columns.map((column) => (
                                        <Header key={column.key}>{column.title}</Header>
                                      ))}
                                    </Head>
                                    <Body>
                                      {datapoint.rows.map((row, rowIndex) => (
                                        <Row
                                          key={rowIndex.toString()}
                                          className="border-b border-gray-300 last:border-0"
                                        >
                                          {row.map((data, columnIndex) => (
                                            <Data key={columnIndex.toString()}>
                                              <Datapoint
                                                allowChangesToggle={allowChangesToggle}
                                                allowHistoryTooltip={allowHistoryTooltip}
                                                data={data}
                                              />
                                            </Data>
                                          ))}
                                        </Row>
                                      ))}
                                    </Body>
                                  </Table>
                                </div>
                              </Data>
                            </Row>
                          );
                        }

                        if (datapoint.datapointComponent === "InputFile") {
                          return (
                            <Row key={datapoint.datapointKey} className="border-b border-gray-300 last:border-0">
                              <Data className="w-3/5">
                                <div className="flex items-center justify-between">
                                  <div>{datapoint.datapointTitle}</div>
                                  <Button
                                    className="h-10 px-4 ml-4"
                                    onClick={() => handleDownloadClick(datapoint?.currValue)}
                                    isDisabled={getAttachmentLinkQuery.isLoading}
                                  >
                                    Download
                                  </Button>
                                </div>
                              </Data>
                              <Data className="w-2/5">
                                <div className="break-all">{datapoint?.currFormattedValue}</div>
                              </Data>
                            </Row>
                          );
                        }

                        return (
                          <Row key={datapoint.datapointKey} className="border-b border-gray-300 last:border-0">
                            <Data className="w-3/5">{datapoint.datapointTitle}</Data>
                            <Data className="w-2/5">
                              <Datapoint
                                allowChangesToggle={allowChangesToggle}
                                allowHistoryTooltip={allowHistoryTooltip}
                                data={datapoint}
                              />
                            </Data>
                          </Row>
                        );
                      })}
                    </Body>
                  </Table>
                </Accordion.Panel>
              ))}

            {process.env.DEFAULT_PRODUCT_REF === "cargo-us" && hasBoundQuote && (
              <CargoPremiums quoteData={contractData.boundQuote} />
            )}

            {process.env.DEFAULT_PRODUCT_REF !== "mc" && !changesOnly && (
              <AccordionPanelClauses
                key="clauses"
                headingText="Clauses"
                clauseData={clauseData}
                handleActions={handleActions}
                isCurrent={isCurrent}
                checkPermissions={checkPermissions}
              />
            )}

            {hasBespokeEndos && (
              <AccordionPanelBespoke
                prevBespokeEndos={contractData?.parentSnapshot?.bespoke_endorsements || []}
                currBespokeEndos={contractData?.bespoke_endorsements || []}
                handleActions={handleActions}
                isEndorsement={isEndorsement}
                changesOnly={changesOnly}
              />
            )}
          </Accordion>
        </div>

        <div className="mb-4 xl:w-1/2  xl:mx-3">
          <QuoteBanners contractData={contractData} />

          {process.env.DEFAULT_PRODUCT_REF !== "cargo-us" &&
            isCurrent &&
            !isEndorsement &&
            checkPermissions(ACTIONS.PROVIDE_COMMERCIAL_PRICE) && (
              <ReferralDecision className="mb-8" contractData={contractData} />
            )}

          {canCreateEndoQuote && (
            <EndoReferralDecision
              className="mb-8"
              contractData={contractData}
              schemaData={clonedSchema}
              endorsementId={endorsementId}
            />
          )}

          {canCancelManual && (
            <EndoCancelReferralDecision
              className="mb-8"
              contractData={contractData}
              schemaData={clonedSchema}
              endorsementId={endorsementId}
            />
          )}

          <Quotes
            checkPermissions={checkPermissions}
            contractData={contractData}
            isCurrent={isCurrent}
            isEndorsement={isEndorsement}
            isProducingBroker={isProducingBroker}
            isBroker={isBroker}
            schemaData={schemaData}
          />

          {isCurrent &&
            !isEndorsement &&
            (checkPermissions(ACTIONS.VIEW_CLAIM_FLAG) || checkPermissions(ACTIONS.VIEW_PREMIUM_PAID_FLAG)) && (
              <div className="mb-8">
                <FlagPolicy contractData={contractData} handleAction={handleActions} isLoading={isUpdating} />
              </div>
            )}
        </div>
      </div>
    </>
  );
};

ContractDetails.propTypes = {
  contractData: PropTypes.object.isRequired,
};

const areEqual = (prevProps, nextProps) =>
  JSON.stringify(prevProps.snapshotData) === JSON.stringify(nextProps.snapshotData) &&
  JSON.stringify(prevProps.contractData) === JSON.stringify(nextProps.contractData) &&
  JSON.stringify(prevProps.clauseData) === JSON.stringify(nextProps.clauseData);

export default memo(ContractDetails, areEqual);
