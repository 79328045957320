import get from "lodash.get";
import { compile } from "path-to-regexp";
import React from "react";
import { Link, Route, useHistory, useParams } from "react-router-dom";
import { ACTIONS, MODALS, ROUTES } from "../../../constants";
import BG from "../../components/ButtonGroup";
import H2 from "../../components/H2";
import Status from "../../components/Status";
import { useAuth } from "../../hooks";
import { useMessagesQuery } from "../../hooks/useMessagesQuery";
import useModal from "../../hooks/useModal";
import NavBar from "../NavBar";
import Avatars from "./Avatars";

const isLocalEnv = process.env.ENVIRONMENT === "local";

const Header = ({
  attachmentsData,
  canCreateEndorsements,
  canCreateRenewal,
  contractData,
  setDebuggerVisible,
  toggleMessageBox,
  schemaVersion,
}) => {
  const { contractId, productRef, endorsementId } = useParams();
  const { showModal, closeModal } = useModal();
  const { checkPermissions, isInternalEnv, isUatEnv, tenants, isAdmin } = useAuth(contractData);
  const { push } = useHistory();
  const { unreadCount } = useMessagesQuery();
  const hasUnread = unreadCount !== 0;
  const contractStatus = get(contractData, "status");
  const contractOwner = tenants.find((t) => t.id === get(contractData, "tenantId")) || {};
  const contractInsured =
    get(contractData, "submission.general_information.insured_name") ||
    get(contractData, "submission.insured.insured_name");
  const contractRef = get(contractData, "ref");
  const renewedFromId = get(contractData, "renewedFrom");
  const attachmentsLength = get(attachmentsData, "length");
  const attachmentsNum = attachmentsLength > 0 && attachmentsLength;
  const unreadNum = unreadCount > 0 && unreadCount;
  const renewedFromUrl =
    productRef && renewedFromId && compile(ROUTES.CONTRACT_VIEW)({ productRef, contractId: renewedFromId });

  const handleConfirmEndo = () => {
    closeModal();
    push(compile(ROUTES.ENDORSEMENT_NEW)({ contractId, productRef }));
  };

  const handleConfirmEndoBespoke = () => {
    closeModal();
    push(compile(ROUTES.ENDORSEMENT_BESPOKE_NEW)({ contractId, productRef }));
  };

  return (
    <div className="pt-16 mt-1.5">
      <NavBar schemaVersion={schemaVersion} />

      <div className="flex justify-between items-center py-6 px-8 bg-white">
        <Route path={[ROUTES.CONTRACT_NEW]}>
          <H2>New quote</H2>
        </Route>

        <Route path={[ROUTES.ENDORSEMENT_NEW]}>
          <H2>New endorsement</H2>
        </Route>

        <Route path={[ROUTES.ENDORSEMENT_BESPOKE_NEW]}>
          <H2>New bespoke endorsement</H2>
        </Route>

        <Route path={[ROUTES.RENEWAL]}>
          <H2>Renewal</H2>
        </Route>

        <Route path={[ROUTES.CONTRACT_EDIT, ROUTES.CONTRACT_VIEW]}>
          <H2>
            {contractInsured}
            <div className="flex text-gray-800 font-normal text-sm mt-2">
              <div className="mr-1">{contractOwner.name} </div>
              {contractRef && (
                <div className="mr-1">&#8226; #{contractRef} </div>
              )}
              {renewedFromId && <Link to={renewedFromUrl}>&#8226; Renewal</Link>}

              {isLocalEnv && (
                <>
                  <div className="mr-1 text-green-600">&#8226; status: {contractData?.status} </div>
                </>
              )}
            </div>
          </H2>
        </Route>

        <Route path={[ROUTES.ENDORSEMENT_EDIT]}>
          <H2>
            {contractInsured}
            <div className="flex text-gray-800 font-normal text-sm mt-2">
              <div className="mr-1">{contractOwner.name} </div>
              <div className="mr-1">&#8226; #{contractRef} </div>
            </div>
          </H2>
        </Route>

        <div className="flex items-center">
          <Route path={[ROUTES.CONTRACT_VIEW]}>{checkPermissions(ACTIONS.VIEW_CONCURRENT_USERS) && <Avatars />}</Route>

          <Route path={[ROUTES.CONTRACT_EDIT, ROUTES.CONTRACT_VIEW, ROUTES.ENDORSEMENT_EDIT]}>
            <Status status={contractStatus} kind="small" className="ml-4" />

            <BG className="ml-4" checkPermissions={checkPermissions}>
              <BG.Button
                action={ACTIONS.REJECT_CONTRACT_NOT_PROGRESSED}
                iconName="cross"
                onClick={() => showModal(MODALS.REJECT_NOT_PROGRESSED)}
              />

              <BG.Button
                action={ACTIONS.REJECT_CONTRACT_NOT_TAKEN_UP}
                iconName="cross"
                onClick={() => showModal(MODALS.REJECT_NOT_TAKEN_UP)}
              />

              <BG.Button
                action={ACTIONS.DECLINE_TO_QUOTE_CONTRACT}
                iconName="cross"
                onClick={() => showModal(MODALS.DECLINE_CONTRACT)}
              />

              {(canCreateEndorsements || isAdmin) && !endorsementId && (
                <BG.Button
                  action={ACTIONS.CANCEL_ENDORSEMENT}
                  iconName="cross"
                  onClick={() => showModal(MODALS.CANCEL_ENDORSEMENT)}
                />
              )}

              <BG.Button
                action={ACTIONS.PROCESS_REFERRAL}
                iconName="double-arrow"
                onClick={() => showModal(MODALS.PROCESS_REFERRAL)}
              />

              <BG.Button
                action={ACTIONS.REFER_CONTRACT}
                iconName="double-arrow"
                onClick={() => showModal(MODALS.REFER_CONTRACT)}
              />

              {canCreateRenewal && process.env.DEFAULT_PRODUCT_REF === "mel" && (
                <BG.Button
                  action={ACTIONS.CREATE_RENEWAL}
                  iconName="replay"
                  onClick={() => push(compile(ROUTES.RENEWAL)({ contractId, productRef }))}
                />
              )}

              {process.env.DEFAULT_PRODUCT_REF === "demo" && (canCreateEndorsements || isAdmin) && !endorsementId && (
                <BG.Button
                  iconName="insert-drive-file"
                  action={ACTIONS.CREATE_ENDORSEMENT_MTA}
                  onClick={() =>
                    showModal(MODALS.CREATE_ENDORSEMENT_WARNING, {
                      handleConfirm: handleConfirmEndo,
                    })
                  }
                />
              )}

              {process.env.DEFAULT_PRODUCT_REF !== "demo" && (
                <BG.Dropdown labelText="Create endorsement">
                  {(canCreateEndorsements || isAdmin) && !endorsementId && (
                    <BG.DropdownItem
                      iconName="insert-drive-file"
                      action={ACTIONS.CREATE_ENDORSEMENT_MTA}
                      onClick={() =>
                        showModal(MODALS.CREATE_ENDORSEMENT_WARNING, {
                          handleConfirm: handleConfirmEndo,
                        })
                      }
                    />
                  )}

                  {(canCreateEndorsements || isAdmin) && !endorsementId && (
                    <BG.DropdownItem
                      iconName="file-copy"
                      action={ACTIONS.CREATE_ENDORSEMENT_BESPOKE}
                      onClick={() =>
                        showModal(MODALS.CREATE_ENDORSEMENT_WARNING, {
                          handleConfirm: handleConfirmEndoBespoke,
                          isBespoke: true,
                        })
                      }
                    />
                  )}
                </BG.Dropdown>
              )}

              {checkPermissions(ACTIONS.VIEW_MESSAGES) && (
                <BG.Button
                  iconName="messages"
                  kind={hasUnread ? "inverted" : "default"}
                  onClick={() => toggleMessageBox()}
                >
                  Messaging {unreadNum && ` (${unreadNum})`}
                </BG.Button>
              )}

              {checkPermissions(ACTIONS.VIEW_CONTRACT_ATTACHMENTS) && (
                <BG.Button iconName="paperclip" onClick={() => showModal(MODALS.ATTACHMENTS)}>
                  Attachments {attachmentsNum && ` (${attachmentsNum})`}
                </BG.Button>
              )}
            </BG>
          </Route>

          {(isInternalEnv || isUatEnv) && (
            <Route
              path={[
                ROUTES.CONTRACT_NEW,
                ROUTES.CONTRACT_EDIT,
                ROUTES.ENDORSEMENT_NEW,
                ROUTES.ENDORSEMENT_BESPOKE_NEW,
                ROUTES.ENDORSEMENT_EDIT,
                ROUTES.RENEWAL,
              ]}
            >
              <BG className="ml-4">
                <BG.Button iconName="find-in-page" kind="green" onClick={() => setDebuggerVisible(true)}>
                  Debug
                </BG.Button>
              </BG>
            </Route>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
