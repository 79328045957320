import clsx from "clsx";
import get from "lodash.get";
import React, { useEffect, useState } from "react";
import { ACTIONS } from "../../../constants";
import ajv from "../../../helpers/ajv";
import Button from "../../components/Button";
import Icon from "../../components/Icon";
import Status from "../../components/Status";
import { useAuth } from "../../hooks";

const NAVIGATOR_CONTAINER_OFFSET = 88;
const HEADER_OFFSET = 80;

const NavItem = ({ onClick, isValid, isActive, title, index }) => (
  <button className="my-4 flex items-center cursor-pointer focus:outline-none" onClick={onClick} type="button">
    <div
      className={clsx(
        "rounded-full h-6 w-6 flex justify-center items-center font-semibold text-xs relative",
        isActive ? "bg-blue-500 text-white" : "bg-gray-200 border text-gray-700",
      )}
    >
      {isValid ? <Icon name="circle-check" className="h-6 w-6" /> : index + 1}
    </div>

    <p className={clsx("mx-3 transition", isActive ? "text-black font-medium" : "text-gray-700 hover:text-gray-900")}>
      {title}
    </p>

    {isValid && <Status kind="small" statusText="Complete" className="text-green-700 bg-green-200" />}

    {isActive && <Status kind="small" statusText="Incomplete" className="text-blue-700 bg-blue-200" />}

    {!isValid && !isActive && <Status kind="small" statusText="Not started" className="text-gray-700 bg-gray-200" />}
  </button>
);

const FormNavigation = ({
  canSaveDraft,
  canSubmit,
  formValues,
  handleSubmit,
  handleSubmitDraft,
  schema,
  showDraftButton,
  handleSaveTemplateClick,
  isRenewal,
}) => {
  const isInternalEnv = ["local", "development", "staging"].includes(process.env.ENVIRONMENT);
  const isUatEnv = ["uat"].includes(process.env.ENVIRONMENT);
  const properties = get(schema, "properties", {});
  const sortedKeys = Object.keys(properties).sort((a, b) => properties[a].qid - properties[b].qid);
  const [isSticky, setIsSticky] = useState("");
  const { checkPermissions } = useAuth();

  const handleScroll = () => setIsSticky(window.pageYOffset > NAVIGATOR_CONTAINER_OFFSET);

  const handleLinkClick = (linkId) => {
    const header = document.getElementById(linkId);

    window.scrollTo({ top: header.offsetTop - HEADER_OFFSET, behavior: "smooth" });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="relative">
      <div className={clsx("shadow rounded bg-white pl-4 py-4 ml-6 min-w-96", isSticky && "fixed top-5")}>
        <div className="relative mb-3">
          <div className="absolute top-4 bottom-4 left-3 w-px bg-gray-200" />
          {sortedKeys.map((key, index) => {
            const section = properties[key] || {};
            const requiredKeys = section.required || [];
            const sectionFormValues = get(formValues, key) || {};
            const formKeys = Object.keys(sectionFormValues).filter((value) => requiredKeys.includes(value));

            const validate = ajv.compile({
              ...schema,
              properties: { [key]: section },
              required: [key],
            });

            const isValid = validate(formValues);
            const isActive = formKeys.length > 0 && !isValid;

            return (
              <NavItem
                index={index}
                isActive={isActive}
                isValid={isValid}
                key={key}
                onClick={() => handleLinkClick(key)}
                title={properties?.[key]?.title}
              />
            );
          })}
        </div>
        <div className="flex border-t pt-6 pb-4 px-4 mr-4 border-gray-300 justify-between">
          <Button className="h-10" isDisabled={!canSubmit} kind="primary" onClick={() => handleSubmit()}>
            Submit
          </Button>

          {showDraftButton && (
            <Button
              className="h-10"
              isDisabled={!canSaveDraft}
              kind="secondary"
              onClick={() => handleSubmitDraft()}
              type="button"
            >
              Save draft
            </Button>
          )}
        </div>

        {(isInternalEnv || isUatEnv) && checkPermissions(ACTIONS.CREATE_DRAFT_SUBMISSION) && !isRenewal && (
          <div className="flex border-t pt-6 pb-4 px-4 mr-4 border-gray-300 justify-between">
            <Button className="h-10" kind="green" onClick={() => handleSaveTemplateClick()}>
              Save template
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default FormNavigation;
