import React from "react";
import { ROUTES, STATUSES } from "../../../constants";
import { dateFormatter, dateTimeFormatter } from "../../../formatters";
import Status from "../../components/Status";

export const filters = {
  [ROUTES.DRAFTS]: [
    STATUSES.DECLINED,
    STATUSES.DRAFT,
    STATUSES.QUOTED,
    STATUSES.REFERRED_QUOTED,
    STATUSES.IN_PROGRESS,
    STATUSES.REFERRED,
    STATUSES.REJECTED_NOT_PROGRESSED,
    STATUSES.REJECTED_NOT_TAKEN_UP,
    STATUSES.EXPIRED,
  ],
  [ROUTES.CONTRACTS]: [STATUSES.BOUND, STATUSES.INFORCE, STATUSES.CANCELLED],
  [ROUTES.REFERRALS]: [STATUSES.REFERRED],
};

export const mcDraftsColumns = [
  {
    className: "w-32",
    displayName: "Reference",
    path: "ref",
  },
  {
    className: "w-48",
    displayName: "Insured",
    path: "submission.base.insured_name",
  },
  {
    className: "w-32",
    displayName: "Created date",
    formatter: dateFormatter,
    path: "createdAt",
  },
  {
    className: "w-32",
    displayName: "Last updated",
    formatter: dateTimeFormatter,
    path: "updatedAt",
  },
  {
    className: "w-32",
    displayName: "Status",
    path: "status",
    formatter: (value) => <Status status={value} kind="small" />,
  },
];

export const mcReferralsColumns = [];

export const mcPolicyColumns = [
  {
    className: "w-32",
    displayName: "Reference",
    path: "ref",
  },
  {
    className: "w-48",
    displayName: "Insured",
    path: "submission.base.insured_name",
  },
  {
    className: "w-32",
    displayName: "Inception date",
    formatter: dateFormatter,
    path: "submission.base.inception_date",
  },
  {
    className: "w-32",
    displayName: "Expiry date",
    formatter: dateFormatter,
    path: "submission.base.expiry_date",
  },
  {
    className: "w-32",
    displayName: "Created date",
    formatter: dateFormatter,
    path: "createdAt",
  },
  {
    className: "w-32",
    displayName: "Last updated",
    formatter: dateTimeFormatter,
    path: "updatedAt",
  },
  {
    className: "w-28",
    displayName: "Policy status",
    path: "status",
    formatter: (value) => <Status status={value} kind="small" />,
  },
];
