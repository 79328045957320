import React from "react";
import Table from "../../components/Table";
import { CargoPremium } from "./CargoPremium";

const { Body, Data, Row } = Table;

export const CargoPremiums = ({ quoteData }) => {
  return (
    <>
      <div>
        <Table>
          <Body>
            <Row className="border-b border-gray-300 last:border-0">
              <Data className="w-3/5">Commission percentage</Data>
              <Data className="w-2/5">{quoteData?.commission ?? "N/A"}</Data>
            </Row>
          </Body>
        </Table>
      </div>

      <CargoPremium name="Transit premium" premium={quoteData?.rates.basePremium} />
      <CargoPremium name="Storage premium" premium={quoteData?.rates?.storagesPremium ?? quoteData?.rates?.storagePremium} />
      <CargoPremium name="Exhibitions premium" premium={quoteData?.rates.exhibitionsPremium} />
      <CargoPremium name="Samples premium" premium={quoteData?.rates.samplesPremium} />
    </>
  );
};
