import PropTypes from "prop-types";
import React from "react";

const AuthLayout = ({ children }) => (
  <div
    className="h-screen flex w-full bg-center bg-cover items-center"
    style={{
      backgroundImage: "url(/sure_background.jpg)",
    }}
  >
    <div className="flex pl-24 w-screen">
      <div className="bg-white w-full max-w-96 xl:max-w-128 shadow-xl flex flex-col overflow-y-auto pt-8 rounded">
        <div className="flex justify-center"></div>
        <div className="px-10 mt-8 flex-auto pb-10">{children}</div>
      </div>

      <div className="flex flex-col self-center pt-10 ml-16"></div>
    </div>
  </div>
);

AuthLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthLayout;
