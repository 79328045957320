import { SchemaRef } from "../../../@types/types";
import { MCPremium, SchemeType } from "../types";
import { getRatesHospitalsAndClinics } from "./hospitals";
import { getRatesSurgeons } from "./surgeons";

export const getRates = (schemaRef: SchemaRef, scheme: SchemeType): MCPremium[] => {
  if (schemaRef === "sure-mc-surgeons") {
    return getRatesSurgeons(scheme);
  }
  if (schemaRef === "sure-mc-hospitals-and-clinics") {
    return getRatesHospitalsAndClinics(scheme);
  }

  throw new Error(`Cannot get rater for schema ref: ${schemaRef}.`);
};
